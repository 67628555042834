<template>
  <Page :mode="isLogin == 'undefined' || isLogin == '' ? 'jacket': ''">
    <h1>{{this.pageContent.Title}}</h1>
    <div class="agb-content">
      <p v-html="this.pageContent.Description"></p>
      <p v-html="this.pageContent.Content"></p>
      <div class="back-login text-left" v-if="isLogin == 'undefined' || isLogin == ''">
       <RouteLink  route="signin" icon="arrow-right" :text="signIn.GoToSignIn"></RouteLink>
      </div>
    </div>
  </Page>
</template>

<script>
import Page from '@/structure/Page.vue'
import RouteLink from '@/structure/RouteLink.vue'
import { vcm } from '@/mixins/vcm'
import { vvm } from '@/mixins/vvm'
import { customLabels } from "@/mixins/customLabels";
import axios from 'axios'

export default {
  mixins: [vcm,vvm, customLabels],
  name: 'agb',
  components: {
    Page, RouteLink
  },
  data() {
    return {
      isLoading: false,
      labels: {
        YourPersonalCitizenAccount: this.get_translation('SignIn','YourPersonalCitizenAccount','label'),
        Introduction: this.get_translation('SignIn','Introduction','label'),        
        SystemInfoHeadline: this.get_translation('App','SystemInfoHeadline','label'),
        SystemAccessError: this.get_translation('App','SystemAccessError','label'),
      },
      pageContent:{
        Title: null,
        Description: null,
        Content : null,
      }
    };
  },

  computed: {
    isLogin() {
      return this.$root.get_access_token;
    }
  },
  async beforeCreate () {
    try {
      this.isLoading = true;
      const Url = process.env.VUE_APP_BASE_URL + '/v1/pages/agb'
      const response = await axios.get(Url, { params: {language: `${this.$root.config.localization.language}`} })
      if (response.data.responseCode === '200' && response.data.response) {
        this.pageContent.Content = response.data.response.Content
        this.pageContent.Description = response.data.response.Description
        this.pageContent.Title = response.data.response.Title;
        this.isLoading = false; 
      }
    } catch (e) {
      console.log('Error : ' + e);
      this.isLoading = false;
    }
  }
}

</script>

<style scoped>
.vgs-jacket-content h1 {
    font-size: 30px;
    margin-bottom: 30px;
    color:$primaryColor;
    text-transform: capitalize;
}
.agb-content{text-align: left;}
.back-login{
  margin-top: 50px;
}

.agb-content p{
    color: #000 !important;
}
</style>

